<template>
   <v-container fluid>
      <v-row>
         <v-col cols="12">
            <v-card class="" elevation="" max-width="">
               <v-card-title class="justify-center"> MODIFICAR COLOR </v-card-title>
               <v-divider></v-divider>
               <v-card-text>
                  <v-row>
                     <v-col cols="12">
                        <p>
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'inicioPanel' }">Inicio</router-link>
                           /
                           <router-link exact="" style="text-decoration: none" :to="{ name: 'productosColoresPanel' }">Colores de lunas</router-link>
                           /
                           <router-link
                              exact=""
                              style="text-decoration: none"
                              :to="{
                                 name: 'productosColoresEditarPanel',
                                 params: { idproducto_color: $route.params.idproducto_color },
                              }"
                              >Editar</router-link
                           >
                        </p>
                     </v-col>
                     <v-col cols="12">
                        <v-divider></v-divider>
                     </v-col>
                  </v-row>

                  <v-row>
                     <v-col cols="12 text-center" v-show="!mostrarContenido">
                        <v-progress-circular indeterminate color="primary" :size="100" :width="7"></v-progress-circular>
                     </v-col>
                  </v-row>

                  <v-form @submit.prevent="modificar()" v-show="mostrarContenido" :disabled="processForm">
                     <v-row>
                        <v-col cols="12">
                           <v-alert type="error" v-model="alertError" dismissible ref="mensajeError" id="mensajeError">
                              <ul v-for="(errors, index) in listErrors" :key="index">
                                 <li v-text="errors[0]"></li>
                              </ul>
                           </v-alert>
                        </v-col>


                        <v-col cols="12">
                           <v-select :items="cmbPosiciones" dense label="Posición" outlined v-model="color.posicion"></v-select>
                        </v-col>

                        <v-col cols="12">
                           <v-text-field dense label="Nombre(*)" v-model="color.nombre"></v-text-field>
                        </v-col>

                        <v-col cols="12">
                           <v-file-input dense v-model="color.imagen" show-size label="Imagen"></v-file-input>

                           <v-img dense contain width="80" height="80" v-if="color.imagen_url != null" :src="color.imagen_url"></v-img>
                        </v-col>

                        <v-col cols="12">
                           <v-select
                              dense
                              item-text="texto"
                              item-value="valor"
                              :items="cmbEstado"
                              label="Estado (*)"
                              outlined
                              v-model="color.estado"
                           ></v-select>
                        </v-col>

                        <v-col cols="12" class="text-center">
                           <v-btn class="mr-2" type="submit" :loading="processForm" color="success" large="">
                              <v-icon left="" large="">mdi-content-save-edit</v-icon> MODIFICAR
                           </v-btn>
                           <v-btn :to="{ name: 'productosColoresPanel' }" type="button" :loading="processForm" color="error" large="">
                              <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                           </v-btn>
                        </v-col>
                     </v-row>
                  </v-form>
               </v-card-text>
            </v-card>
         </v-col>
      </v-row>
   </v-container>
</template>

<script>
   export default {
      data() {
         return {
            color: {
               _method: "PUT",
               idproducto_color: null,
               nombre: "",
               codigo: "",
               estado: 1,
               posicion: "",
               imagen: null,
               imagen_url: null,
            },
            cmbLunas: [],
            cmbPosiciones: [],
            cmbEstado: [
               {
                  texto: "Habilitado",
                  valor: 1,
               },
               {
                  texto: "Inhabilitado",
                  valor: 0,
               },
            ],
            mostrarContenido: false,
            processForm: false,
            listErrors: [],
            alertError: false,
         };
      },

      methods: {
         getDatos() {
            this.mostrarContenido = false;
            this.axios({
               method: "GET",
               url: "api/panel/productos-colores/" + this.$route.params.idproducto_color + "/edit",
            })
               .then((response) => {
                  let status = response.status;
                  if (status === 200) {
                     let color = response.data.color;
                     let lunas = response.data.lunas;

                     this.cmbLunas = lunas;
                     this.color.idproducto_color = color.idproducto_color;
                     this.color.idluna = color.idluna;
                     this.color.nombre = color.nombre;
                     this.color.codigo = color.codigo;
                     this.color.estado = color.estado;
                     this.color.posicion = color.posicion;
                     this.color.imagen_url = color.imagen_url;
                     this.getOrden();
                  }

                  this.mostrarContenido = true;
               })
               .catch((error) => {
                  this.$toasted.error("Ocurrio un error al buscar este registro", {
                     icon: "mdi-close",
                  });

                  this.$router.push({
                     name: "productosColoresPanel",
                  });
               })
               .finally(() => {});
         },

         modificar() {
            this.processForm = true;
            this.alertError = false;
            this.axios({
               method: "POST",
               url: "api/panel/productos-colores/" + this.color.idproducto_color,
               data: this.serialize(this.color),
            })
               .then((response) => {
                  let status = response.status;
                  if (status === 200) {
                     this.$toasted.success(response.data.data, {
                        icon: "mdi-check-bold",
                     });
                     this.$router.push({
                        name: "productosColoresPanel",
                     });
                  }
               })
               .catch((error) => {
                  let status = error.response.status;
                  if (status === 422) {
                     let arrErrors = error.response.data.errors;
                     this.listErrors = Object.values(arrErrors);
                     this.alertError = true;
                     this.$vuetify.goTo(this.$refs.mensajeError);
                  } else {
                     this.$toasted.error("Ocurrio un error al modificar este registro", {
                        icon: "mdi-close",
                     });
                  }
               })
               .finally(() => {
                  this.processForm = false;
               });
         },

         getOrden() {
            this.axios
               .post("api/panel/productos-colores/getOrden", { idluna: this.color.idluna })
               .then( response => {
                  const data = response.data;

                  this.cmbPosiciones = [];
                  for (let i = 1; i <= data.posicion; i++) {
                     this.cmbPosiciones.push(i);
                  }
               })
               .catch((error) => {
                  console.error(error);
               });
         },
      },
      created() {
         window.scrollTo(0, 0);

         this.getDatos();
      },
   };
</script>
